import passHolderService from '../../passHolderService'
import { FromLocation, SearchTerm } from './'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { AppAsyncThunkOptions } from '../../../../store'
import { AggregateMetadata, Filter, PassHolderSearchSummary, PassHolderSummary } from '../../../../types/api'
import { requestErrorHandler } from '../../../../services/request'

export interface LinkedProfileResponse {
  pageSize: number
  page: number
  result: PassHolderSearchSummary[]
  filter?: AggregateMetadata
  appliedFilters?: SearchTerm[]
  totalRecords: number
}

export interface LinkedProfileRequest {
  page: number
  pageSize?: number
  filter?: SearchTerm[]
  justification?: string
}

export interface CompareRequest {
  compareProfileId: string
  justification?: string
}

export const fetchPassHolder = createAsyncThunk<PassHolderSummary, string, AppAsyncThunkOptions>
  ('PassHolder/fetchPassHolder', async (passHolderId, { getState, rejectWithValue }) => {
    const state = getState()

    const { fromLocation } = state.passHolder

    let justification: string | undefined
    if (fromLocation === FromLocation.search) {
      justification = state.search.justification.justification
    } else if (fromLocation === FromLocation.bulkSearch) {
      justification = state.bulkSearch.justification.justification
    } else {
      justification = state.search.justification.justification || state.bulkSearch.justification.justification
    }

    try {
      const result = await passHolderService.requestPassHolder(passHolderId, justification)
      return result.data
    }
    catch (e) {
      return rejectWithValue(requestErrorHandler(e as Error))
    }
  })

export const fetchCompareProfiles = createAsyncThunk<PassHolderSummary, CompareRequest, AppAsyncThunkOptions>
  ('PassHolder/linkedProfiles/compare', async ({ compareProfileId, justification }, { getState, rejectWithValue }) => {
    const state = getState()
    if (!state.passHolder.passHolderId) {
      return rejectWithValue('No passholder found')
    }
    try {
      const result = await passHolderService.requestCompare(state.passHolder.passHolderId, compareProfileId, justification)
      return result.data
    } catch (e) {
      return rejectWithValue(requestErrorHandler(e as Error))
    }
  })

export const fetchLinkedProfiles = createAsyncThunk<LinkedProfileResponse, LinkedProfileRequest, AppAsyncThunkOptions>
  ('PassHolder/linkedProfiles', async (request, { getState, rejectWithValue }) => {
    const state = getState()

    const {
      pageSize
    } = state.passHolder.linkedProfiles

    const {
      page,
      justification
    } = request

    const filter = request.filter
      ? combineFilter(request.filter)
      : undefined

    if (!state.passHolder.passHolderId) {
      return rejectWithValue('Pass holder has not loaded')
    }

    try {
      const {
        data: {
          records,
          totalRecords,
          metadata
        }
      } = await passHolderService.requestLinkedProfiles(state.passHolder.passHolderId, justification, page, pageSize, filter)

      const query = metadata?.query
      let resultFilter: AggregateMetadata | undefined
      if (query) {
        const resultFilterBuilder: AggregateMetadata = {}
        for (const highlight of Object.entries(query)) {
          const value = highlight[1] as string[]
          resultFilterBuilder[highlight[0]] = value
        }
        resultFilter = resultFilterBuilder
      }

      return {
        result: records,
        page,
        pageSize,
        filter: resultFilter,
        appliedFilters: request.filter,
        totalRecords
      }
    } catch (e) {
      return rejectWithValue(requestErrorHandler(e as Error))
    }
  })

const combineFilter = (terms: SearchTerm[]) => {
  const filter: Filter = {}
  for (const term of terms) {
    const current = filter[term.key]
    const array = current?.values ? current.values : []

    array.push(term.value)
    filter[term.key] = {
      dataType: 'text',
      type: 'match',
      values: array
    }
  }
  return filter
}