import { PassHolderBulkSearchRecord, PassHolderSearchSummary } from '../../../../types/api'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { BULKSEARCH_RESULTS, reset } from './actions'
import * as filter from '../../../../state/filter/reducers'
import { initialState as initialFilterState, FilterState } from '../../../../state/filter/reducers'
import { executeBulkSearch } from './thunks'
import { uploadFile } from '../upload'
import _ from 'lodash'
import { getRejectionError } from '../../../../services/request'
import { BreadboxItem } from '../../../../types/BreadBoxItem'

export type BulkSearchResultsState = FilterState & {
  isFetching: boolean
  hasSearched: boolean
  withFilters: boolean
  records: PassHolderSearchSummary[]
  totalRecords: number
  currentPage: number
  pageSize: number
  termsError: string | null
  error: string | null
  query: PassHolderBulkSearchRecord[]
  termsBreadboxResults: PassHolderBulkSearchRecord[],
  termsBreadboxItems: BreadboxItem[],
  breadBoxShouldUpdate: boolean
}

export const initialState: BulkSearchResultsState = {
  ...initialFilterState,
  isFetching: false,
  hasSearched: false,
  withFilters: false,
  records: [],
  totalRecords: 0,
  currentPage: 1,
  pageSize: 20,
  termsError: null,
  error: null,
  query: [],
  termsBreadboxResults: [],
  termsBreadboxItems: [],
  breadBoxShouldUpdate: false,
}

const slice = createSlice({
  name: BULKSEARCH_RESULTS,
  initialState,
  reducers: {
    removeTermsBreadboxItem(state, action: PayloadAction<string>) {
      const valueToRemove = action.payload

      const isRecordEmpty = (record: PassHolderBulkSearchRecord) => Object
        .values(record)
        .every(value => {
          return value.length === 0
        })

      state.query = state.query.map(record => {

        const concatenatedNames = `${record.givenNames} ${record.surname}`
        if (concatenatedNames === valueToRemove) {
          record.givenNames = ''
          record.surname = ''
        }
        const concatenatedNamesAndDateOfBirth = `${record.givenNames} ${record.surname} & ${record.date_of_birth}`
        if (concatenatedNamesAndDateOfBirth === valueToRemove) {
          record.givenNames = ''
          record.surname = ''
          record.date_of_birth = ''
        }
        if (record.givenNames === valueToRemove) {
          record.givenNames = ''
        }
        if (record.surname === valueToRemove) {
          record.surname = ''
        }
        if (record.date_of_birth === valueToRemove) {
          record.date_of_birth = ''
        }
        record.others = record.others.filter(
          otherValue => otherValue !== valueToRemove
        )

        return record;
      }).filter(record => !isRecordEmpty(record))
    },
    setBreadBoxItems(state, action: PayloadAction<BreadboxItem[]>) {
      const breadBoxItems = action.payload
      state.breadboxItems = breadBoxItems
      state.breadBoxShouldUpdate = false
    },
    setTermsBreadboxItems(state, action: PayloadAction<BreadboxItem[]>) {
      const termsBreadboxItems = action.payload
      state.termsBreadboxItems = termsBreadboxItems
      state.breadBoxShouldUpdate = false
    },
    toggleSideMenuExpanded: filter.toggleSideMenuExpanded,
    toggleSideMenuEntryExpanded: filter.toggleSideMenuEntryExpanded,
    removeBreadboxItem: filter.removeBreadboxItem,
    clearDateFilter: filter.clearDateFilter,
    addSelectedSuggestion: filter.addSelectedSuggestion,
    setSideMenuCheckbox: filter.setSideMenuCheckbox,
    setSideMenuDateFilter: filter.setSideMenuDateFilter
  },
  extraReducers: (builder) => {
    builder.addCase(reset, () => initialState)
    builder.addCase(executeBulkSearch.fulfilled, (state, action) => {
      const {
        result: { data: { records, totalRecords, metadata } },
        appliedFilters,
        saveAggregation,
        page
      } = action.payload

      const hasFilters = Object.keys(appliedFilters).length > 0
      const hasResults = totalRecords > 0

      if (metadata) {
        state.query = metadata.query
        if (saveAggregation) {
          const aggregation = metadata.aggregations || {}
          state.sideMenuEntryTopMatches = aggregation
        }
      }
      state.isFetching = false
      state.hasSearched = true
      state.withFilters = hasFilters
      state.records = records
      state.totalRecords = totalRecords
      state.currentPage = page
      state.error = initialState.error
      state.sideMenuExpandedStatus = hasFilters || hasResults
      state.termsBreadboxResults = state.query
      state.breadBoxShouldUpdate = true
    })
    builder.addCase(executeBulkSearch.rejected, (_payload, action) => {
      let error = null
      let termsError = null

      if (typeof action.payload == 'string') {
        termsError = action.payload
      } else {
        error = getRejectionError(action, initialState.error)
      }

      return {
        ...initialState,
        termsError,
        error,
        hasSearched: true
      }
    })
    builder.addCase(uploadFile.fulfilled, (state, action) => {
      const { data: { records, totalRecords, metadata } } = action.payload
      state.currentPage = 1

      const hasResults = totalRecords > 0

      if (metadata) {
        state.query = metadata.query
        const aggregation = metadata.aggregations || {}
        state.sideMenuEntryTopMatches = aggregation
      }
      state.isFetching = false
      state.records = records
      state.totalRecords = totalRecords
      state.hasSearched = true
      state.withFilters = false
      state.termsBreadboxResults = state.query
      state.breadBoxShouldUpdate = true
      state.sideMenuExpandedStatus = hasResults
    })
  }
})

export * from './thunks'

export * from './actions'

export const {
  addSelectedSuggestion,
  clearDateFilter,
  setBreadBoxItems,
  setTermsBreadboxItems,
  removeBreadboxItem,
  removeTermsBreadboxItem,
  setSideMenuCheckbox,
  setSideMenuDateFilter,
  toggleSideMenuEntryExpanded,
  toggleSideMenuExpanded
} = slice.actions

export default slice.reducer