import {
  AccountActivitySummary,
  Aggregate,
  ApiSuccessResult,
  Filter,
  PageResult
} from '../../types/api'
import queryString from 'query-string'
import axios from 'axios'

const path = '/api/v1/users/search'

const getAllAccountActivity = async (page: number, pageSize: number, sortBy: string, sortOrder: string, filter?: Filter, aggregates?: Aggregate[]) => {
  const payload = {
    filter,
    aggregates
  }
  const result = await axios.post<PageResult<AccountActivitySummary>>(`${path}?${queryString.stringify({
    page,
    pageSize,
    sortBy,
    sortOrder
  })}`, payload)
  return result.data
}

const getUsersSuggestions = async (field: string, aggregateField: string, value: string) => {
  const query = {
    aggregateField,
    searchField: field,
  }

  const payload = {
    value: value
  }

  const result = await axios.post<ApiSuccessResult<string[]>>(`/api/v1/users/search/suggestions?${queryString.stringify(query)}`, payload)
  return result.data
}

export default {
  getAllAccountActivity,
  getUsersSuggestions
}
