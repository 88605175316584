import axios from 'axios'
import queryString from 'query-string'
import fileSaver from 'file-saver'
import { ReportStatus, SortOrder, Filter, Aggregate, SortRequest, PageRequest, PageResult, ReportSummary, ApiSuccessResult } from '../../types/api'
import _ from 'lodash'
import response from '../../services/response'

interface ReportQueryRequest extends PageRequest, SortRequest {

}

const getReportStatusName = (value?: ReportStatus) => {
  if (!value) {
    return 'Unknown'
  }

  return {
    processing: 'Processing',
    failed: 'Failed',
    completed: 'Completed',
    completed_with_warnings: 'Failed'
  }[value] || 'Unknown'
}

const requestReports = async (
  page: number,
  pageSize: number,
  sortBy: string,
  sortOrder: SortOrder,
  filter?: Filter,
  aggregates?: Aggregate[]
) => {
  const query: ReportQueryRequest = { page, pageSize, sortBy, sortOrder }

  const payload = {
    filter: _.isEmpty(filter) ? undefined : filter,
    aggregates: _.isEmpty(aggregates) ? undefined : aggregates
  }

  const result = await axios.post<PageResult<ReportSummary>>(
    `/api/v1/reports/search?${queryString.stringify(query)}`,
    payload
  )
  return result.data
}

const download = async (index: string, id: string) => {
  const url = `/api/v1/reports/${index}/${id}/download`

  const res = await axios.get(url, { responseType: 'arraybuffer' })
  const contentType = res.headers['content-type']

  const fileName = response.getFilenameFromContentDisposition(res, 'report.pdf')

  const blob = new Blob([res.data], { type: contentType })

  fileSaver.saveAs(blob, fileName)
}

const getReportsSearchSuggestions = async (field: string, aggregateField: string, value: string) => {
  const query = {
    aggregateField,
    searchField: field,
    value
  }

  const payload = {
    value: value
  }

  const result = await axios.post<ApiSuccessResult<string[]>>(`/api/v1/reports/search/suggestions?${queryString.stringify(query)}`, payload)
  return result.data
}

export default {
  getReportStatusName,
  requestReports,
  download,
  getReportsSearchSuggestions
}
