import searchSideMenuFilters from '../../../../constants/searchSideMenuFilters'
import { getAggregates } from '../../../../services/aggregates'
import { getFilters } from '../../../../services/filters'
import { AppAsyncThunkOptions, AppThunk } from '../../../../store'
import { Filter, PageResult, PassHolderSearchSummary } from '../../../../types/api'
import searchService from '../../../Search/searchService'
import { SortList } from '../../../Sort/state'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { BulkSearchMetadata } from '../upload'
import { requestErrorHandler } from '../../../../services/request'

interface SearchRequest {
  page: number
  isNewBulkSearchTerm: boolean
}

export interface SearchResponse {
  result: PageResult<PassHolderSearchSummary, BulkSearchMetadata>
  saveAggregation: boolean
  appliedFilters: Filter
  page: number
}

export const executeBulkSearch = createAsyncThunk<SearchResponse, SearchRequest, AppAsyncThunkOptions>
  ('BulkSearch/executeBulkSearch', async ({ page, isNewBulkSearchTerm }, { getState, rejectWithValue }) => {
    const state = getState()

    const {
      query,
      pageSize,
      sideMenuCheckboxStatus,
      sideMenuDateFilters,
      sideMenuTextMatchesStatus
    } = state.bulkSearch.results

    const {
      sortBy,
      sortOrder
    } = state.sort

    const searchSortBy = sortBy[SortList.BulkSearchResults]
    const searchSortOrder = sortOrder[SortList.BulkSearchResults]

    const { justification } = state.bulkSearch.justification
    const { bulkSearchType } = state.bulkSearch.bulkSearchType

    const hasBulkSearchTerms = !!query && query.length > 0

    if (!hasBulkSearchTerms) {
      return rejectWithValue('There are no search results')
    }

    const saveAggregation = isNewBulkSearchTerm
    const filters = getFilters(searchSideMenuFilters, sideMenuCheckboxStatus, sideMenuDateFilters, sideMenuTextMatchesStatus)
    const aggregates = getAggregates(saveAggregation, searchSideMenuFilters)

    try {
      const result = await searchService.updateBulkSearch(query, justification, searchSortBy, searchSortOrder, page, pageSize, filters, aggregates, bulkSearchType)
      return {
        result,
        saveAggregation,
        appliedFilters: filters,
        page
      }
    }
    catch (e) {
      return rejectWithValue(requestErrorHandler(e as Error))
    }
  })

export const updateBulkSearch = (isNewBulkSearchTerm = false): AppThunk => async (dispatch): Promise<void> => {
  const page = 1
  dispatch(executeBulkSearch({ page, isNewBulkSearchTerm }))
}

export const changePage = (page: number): AppThunk => (dispatch) => {
  const isNewBulkSearchTerm = false
  dispatch(executeBulkSearch({ page, isNewBulkSearchTerm }))
}
